<template>
    <div>
        <div class="text-xs-right" v-if="$store.state.lengthActiveCampaigns < 3">
            <v-btn class="white--text elevation-6" small round color="teal darken-2" :to="{name: 'campaigns.new_campaign'}" >
                <v-icon left class="fas fa-plus " > </v-icon>
                <span> Create New Campaign</span>
            </v-btn>
        </div>
        <div class="text-xs-right" v-else>
            <v-btn class="white--text elevation-6" small round color="teal darken-2" @click="completeActiveCampaignMsg()" >
                <span> Create New Campaign</span>
            </v-btn>
        </div>

        <div v-if="campaigns.length === 0">
            <h1>No {{$route.params.category}} campaigns exists yet</h1>
        </div>
        <v-card class="elevation-4 mt-2" style="border-radius: 10px" v-for="item in campaigns" :key="item.key">
            <v-layout row wrap text-xs-center v-if="item.principal">
                <h2 class="pa-2 dark--text text-xs-left"><span class="blue--text">Current Account:</span> {{item.saved_amount}} FCFA</h2>
            </v-layout>
        </v-card>

        <div class="mt-3 pa-0" style="max-height: 400px; overflow: auto"> 
            <v-hover v-for="item in campaigns" :key="item.id">
                <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 18 : 4}`"  color="white" style=""
                    :key="item.key" 
                    :to="'/savings/campaigns/detail/'+item.id"
                >
                    <v-card-text class="px-3 py-2 " v-if="!item.principal" style="font-size: 12px; cursor: pointer">
                        <h2 :class="titleColor">Campaign: {{item.name}}<blink v-if="item.notification" class="pt-0 mt-0" style="font-size: 8px; position: relative; top: 0"><i class="red--text fas fa-circle"></i></blink></h2>
                        <span><b>Creation Date:</b> {{item.get_date_str}}</span>
                        <v-spacer></v-spacer>
                        <div v-if="item.target_amount && item.target_date" >
                            <span><b>Objective:</b> Reach {{item.target_amount}} FCFA By the {{item.target_date}} </span>
                        </div>
                        <div v-else-if="item.target_amount" >
                            <span><b>Objective:</b> Reach {{item.target_amount}} FCFA </span>
                        </div>
                        <div v-else-if="item.target_date" >
                            <span><b>Objective:</b> On the {{item.target_date}} </span>
                        </div>
                    </v-card-text>
                <v-divider></v-divider>
                </v-card>
            </v-hover>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                page: 1,
                campaigns: [],
                titleColor: 'teal--text',
            }
        },

        methods:{
            completeActiveCampaignMsg(){
                this.$store.commit('setSnackBarMessage', "Your active campaigns have reached the maximum limit of 2, please complete them first.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            },
            async getCampaigns(){
                this.$store.commit('setIsLoading', true)

                const campaign_category = this.$route.params.category  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/savings/get/user/campaigns/'+campaign_category+'/')
                    .then(response => {
                        this.campaigns = response.data  // get the data and fill into campaigns
                        if (campaign_category === 'all'){
                            this.titleColor = 'grey--text'
                        }
                        else if (campaign_category === 'active'){
                            this.titleColor = 'teal--text'
                            this.$store.commit('setLengthActiveCampaigns', this.campaigns.length)
                        }
                        else if (campaign_category === 'interrupted'){
                            this.titleColor = 'red--text'
                        }
                        else if (campaign_category === 'completed'){
                            this.titleColor = 'blue--text'
                        }

                    })
                    .catch(error => {
                        if (error.response){
                            console.log(error.response)
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },
        
        watch: {
            '$route': function(to, from) {
                this.getCampaigns();
            }
        },

        mounted(){
            // executes these after the page has been mounted
            this.getCampaigns();
            const campaign_category = this.$route.params.category  // get campaign category from url and send request to server
            document.title = "PettyCash | "+campaign_category+" user's campaigns"
            sessionStorage.setItem('url', window.location.href);
            this.$store.commit('setCloseURL', "/savings")
            this.$store.commit('setSaveItem', "")
        },
    }
</script>

<style scoped>
    blink {
    -webkit-animation: 4s linear infinite condemned_blink_effect; /* for Safari 4.0 - 8.0 */
    animation: 4s linear infinite condemned_blink_effect;
    }

    /* for Safari 4.0 - 8.0 */
    @-webkit-keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

    @keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
    }

</style>

